var Utility = {
    definePatternHeight: function () {
        var height = document.body.clientHeight;
        var bodyHeight = height;
        $('.pattern-overlay').css('height', bodyHeight);
    },
    defineBackToTop: function () {
        if (!Math.trunc) {
            Math.trunc = function(v) {
                v = +v;
                if (!isFinite(v)) return v;
                
                return (v - v % 1)   ||   (v < 0 ? -0 : v === 0 ? v : 0);
                
                // returns:
                //  0        ->  0
                // -0        -> -0
                //  0.2      ->  0
                // -0.2      -> -0
                //  0.7      ->  0
                // -0.7      -> -0
                //  Infinity ->  Infinity
                // -Infinity -> -Infinity
                //  NaN      ->  NaN
                //  null     ->  0
            };
        }
        // back to top button on scroll
        var s = $(window).scrollTop(),
            d = $(document).height(),
            c = $(window).height();

        var scrollPercent = Math.trunc((s / (d - c)) * 100);
        // If Scroll is > 50 show back_to_top btn, 
        if (scrollPercent >= 50) {
            setTimeout(function () {
                $('#back_to_top').removeClass('animate-out');
                $('#back_to_top').addClass('animate-in');
            }, 500)
        }
        // if scroll is < 10 hide back_to_top btn
        if (scrollPercent <= 10) {
            setTimeout(function () {
                $('#back_to_top').removeClass('animate-in');
                $('#back_to_top').addClass('animate-out');
            }, 500)
        }
    },
    setEqualHeight: function (element) {
        if ($(window).width() > 768) {
        var maxContentHeight = 0;
        $(element).each(function () {
            if ($(this).height() > maxContentHeight) {
                maxContentHeight = $(this).height();
            }
        });
        $(element).height(maxContentHeight);
    }
    },
    navbarScroll: function () {

        $(function () {
            var $nav = $(".header-container");
            var $header = $('.header-container');
            var $body = $('body');
            $(document).scroll(function () {
                $nav.toggleClass('scrolled', $(this).scrollTop() > $header.height());
                if ($nav.hasClass('scrolled')) {
                    $('.header-nav-top').hide(300);
                } else {
                    $('.header-nav-top').show(300);
                }
            });

            $body.css('margin-top', $header.height());
            if ($('.header-container').offset().top > $('.header-container').height()) {
                $('.header-container').addClass('scrolled');
            }
        });
    }
}

module.exports = Utility;