const prevArw = '<button type="button" class="navi navi-prev"><i class="fas fa-angle-left"></i></button>';
const nextArw = '<button type="button" class="navi navi-next"><i class="fas fa-angle-right"></i></button>';
// var imgurl = '../assets/images/icons/nav-left.png';
const prevArwImg = '<button type="button" class="navi navi-img navi-prev"></button>';
const nextArwImg = '<button type="button" class="navi navi-img navi-next"></button>';
var pageCarouselItems = $('.page_carousel_wrapper').data('carousel-items');
// Slider settings
var window_correction = 17;
var carouselOptions = {
    slidesToShow: 4,
    dots: false,
    speed: 800,
    infinite: false,
    prevArrow: prevArw,
    nextArrow: nextArw,
    autoplay: true,
    centerPadding: '0px',
    responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1
            }
        }
    ]
}

var carouselGroupOptions = {
    slidesToShow: 4,
    dots: false,
    speed: 800,
    infinite: true,
    prevArrow: prevArw,
    nextArrow: nextArw,
    autoplay: false,
    centerPadding: '0px',
    responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1
            }
        }
    ]
}



var headerOptions = {
    slidesToShow: 1,
    dots: false,
    infinite: false,
    speed: 800,
    fade: true,
    prevArrow: prevArw,
    nextArrow: nextArw,
    autoplay: true,
}
var slidersOptions = {
    slidesToShow: 1,
    dots: false,
    infinite: false,
    speed: 800,
    fade: true,
    prevArrow: prevArw,
    nextArrow: nextArw,
    autoplay: true,
    adaptiveHeight: true,
}

var referenceDetailOptions = {
    slidesToShow: 1,
    dots: true,
    infinite: false,
    speed: 800,
    fade: true,
    dots: true,
    prevArrow: prevArwImg,
    nextArrow: nextArwImg
}
var logoOptions = {
    lazyLoad: 'ondemand',
    slidesToShow: 7,
    slidesToScroll: 7,
    prevArrow: prevArwImg,
    nextArrow: nextArwImg,
    responsive: [{
            breakpoint: 1600,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
            }
        },
        {
            breakpoint: 1350,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 556,
            settings: {
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '80px',
            }
        }
    ]
}
var commentsOptions = {
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    slidesToScroll: 1,
    // prevArrow: prevArw,
    // nextArrow: nextArw,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    dots: false,
}
var horecaOptions = {
    slidesToShow: 3,
    dots: false,
    speed: 800,
    infinite: false,
    arrows: false,
    autoplay: false,
    centerPadding: '0px',
    responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 2

            }
        },
        {
            breakpoint: 662,
            settings: {
                slidesToShow: 1
            }
        }
    ]
}
// function headerSlider() {
//     if ($('.header-slider').length) {
//         $('.header-slider').slick(headerOptions);
//     }
// }

// function carouselSlider() {
//     if ($('.page_carousel_wrapper').length) {
//         // $('.page_carousel_wrapper').on('init', function (event, slick) {
//         //     console.log(event, slick);
//         // });
//         $('.page_carousel_wrapper').slick(carouselOptions);
//     }
//     if ($('.group_carousel_init').length) {
//         $('.group_carousel_init').slick(carouselGroupOptions);
//     }
// }

// function sliders() {
//     if ($('.slider_init').length) {
//         $('.slider_init').slick(slidersOptions);
//     }
//     if ($('.second_slider_init').length) {
//         $('.second_slider_init').slick(slidersOptions);
//     }
//     if ($('.third_slider_init').length) {
//         $('.third_slider_init').slick(slidersOptions);
//     }
//     if ($('.logo_slider_init').length) {
//         $('.logo_slider_init').slick(logoOptions);
//     }
// }

// function referencesSlider() {
//     if ($('.references-slider').length) {
//         $('.references-slider').slick(referencesOptions);
//     }
// }

// function commentsSlider() {
//     if ($('.comments_slider_init').length) {
//         $('.comments_slider_init').slick(commentsOptions);
//     }
// }

// function onInit() {
//     $('.slider_init').on('init', function (slick) {
//         $('.slider_item').each(function (e) {

//             var sliderImg = parseInt($(this).children('.slider-img').height());
//             var sliderContent = parseInt($(this).children('.item-content').height());

//             if (sliderImg < sliderContent) {
//                 $(this).removeClass('vertical-center');
//             }
//             if ($(this).hasClass('slider-item-right') && $(this).hasClass('vertical-center')) {
//                 if ($(window).width() > 769 - window_correction) {
//                     var sliderHtml = $(this).html();
//                     var sliderImg = $(this).children('.slider-img');
//                     var sliderContent = $(this).children('.item-content');
//                     $(this).empty();
//                     $(this).append(sliderContent);
//                     $(this).append(sliderImg);
//                 }
//             }


//         })
//     })
// }



var Slider = {
    headerSlider: function (method) {
        if (method == 'init') {
            if ($('.header-slider').length) {
                $('.header-slider').slick(headerOptions);
            }
        }
        if (method == 'refresh') {
            if ($('.header-slider').length) {
                $('.header-slider').slick('refresh');
            }
        }
    },
    sliders: function (method) {
        if (method == 'init') {
            if ($('.slider_init').length) {
                $('.slider_init').slick(slidersOptions);
            }
            if ($('.second_slider_init').length) {
                $('.second_slider_init').slick(slidersOptions);
            }
            if ($('.third_slider_init').length) {
                $('.third_slider_init').slick(slidersOptions);
            }
            if ($('.logo_slider_init').length) {
                $('.logo_slider_init').slick(logoOptions);
            }
        }

        if (method == 'refresh') {
            if ($('.slider_init').length) {
                $('.slider_init').slick('refresh');
            }
            if ($('.second_slider_init').length) {
                $('.second_slider_init').slick('refresh');
            }
            if ($('.third_slider_init').length) {
                $('.third_slider_init').slick('refresh');
            }
            if ($('.logo_slider_init').length) {
                $('.logo_slider_init').slick('refresh');
            }
        }
    },
    carouselSlider: function (method) {
        if (method == 'init') {
            if ($('.page_carousel_wrapper').length) {
                $('.page_carousel_wrapper').slick(carouselOptions);
            }
            if ($('.group_carousel_init').length) {
                $('.group_carousel_init').slick(carouselGroupOptions);
            }
        }
        if (method == 'refresh') {
            if ($('.page_carousel_wrapper').length) {
                $('.page_carousel_wrapper').slick('refresh');
            }
            if ($('.group_carousel_init').length) {
                $('.group_carousel_init').slick('refresh');
            }
        }
    },
    referencesSlider: function () {
        if ($('.references-slider').length) {
            $('.references-slider').slick(referencesOptions);
        }
    },
    commentsSlider: function (method) {
        if (method == 'init') {
            if ($('.comments_slider_init').length) {
                $('.comments_slider_init').slick(commentsOptions);
            }
        }
        if (method == 'refresh') {
            if ($('.comments_slider_init').length) {
                $('.comments_slider_init').slick('refresh');
            }
        }
    },
    horecaSlider: function (method) {
        if (method == 'init') {
            if ($('.horeca_init').length) {
                $('.horeca_init').slick(horecaOptions);
            }
        }
        if (method == 'refresh') {
            if ($('.horeca_init').length) {
                $('.horeca_init').slick('refresh');
            }
        }
    },
    onInit: function () {
        if ($('.slider_init').length) {
            $('.slider_init').on('init', function (slick) {
                verticallyAlign($(this));
            })
        }
        if ($('.second_slider_init').length) {
            $('.second_slider_init').on('init', function (slick) {
                verticallyAlign($(this));
            });
        }
        if ($('.third_slider_init').length) {
            $('.third_slider_init').on('init', function (slick) {
                verticallyAlign($(this));
            })
        }
    }
}

function verticallyAlign(item) {
    item.find('.slider_item').each(function (e) {
        var sliderImg = parseInt($(this).children('.slider-img').height());
        var sliderContent = parseInt($(this).children('.item-content').height());

        if (sliderImg < sliderContent) {
            $(this).removeClass('vertical-center');
        }
        if ($(this).hasClass('slider-item-right') && $(this).hasClass('vertical-center')) {
            if ($(window).width() > 769 - window_correction) {
                var sliderHtml = $(this).html();
                var sliderImg = $(this).children('.slider-img');
                var sliderContent = $(this).children('.item-content');
                $(this).empty();
                $(this).append(sliderContent);
                $(this).append(sliderImg);
            }
        }


    })
}

// module.exports.commentsSlider = commentsSlider;
// module.exports.carouselSlider = carouselSlider;
// module.exports.headerSlider = headerSlider;
// module.exports.referencesSlider = referencesSlider;
// module.exports.sliders = sliders;
// module.exports.onInit = onInit;

module.exports = Slider;